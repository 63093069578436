<template>
  <b-row>
    <b-col cols="12" xl="3" class="nopadding">
      <b-card>
        <div class="text-center">
          <img v-if="unit&&unit.logo" :src="computed_logo(unit.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t("common.choose_another_image")}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group v-if="is_super">
              <label for="ddl_business">{{$t("common.business")}}</label>
              <div v-if="$v.unit.business.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.unit.business.required">{{$t("common.select_required")}}.</div>
              </div>
              <b-form-select id="ddl_business" v-model="unit.business"
                v-on:input="$v.unit.business.$touch">
                  <option value="null" disabled>{{$t("common.select_unit")}}</option>
                  <option v-for="d in options_business" :value="d.code"
                      v-bind:key="d.code">{{d.name[$i18n.locale]}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="txt_name">{{$t("common.name")}}</label>
              <div v-if="$v.unit.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.unit.name.required">{{$t("common.name_required")}}.</div>
                  <div class="text-danger" v-else-if="!$v.unit.name.isUnique">{{$t("common.name_unique")}}</div>
              </div>
              <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_name')"
              v-model="unit.name" maxlength="50" ref="txt_name" size="sm"
              v-on:input="$v.unit.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_des">{{$t("common.description")}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="unit.des" maxlength="200"
              :placeholder="$t('common.placeholder_description')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-info" class="mr-1" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t("common.refresh")}}</b-button>
          <b-button variant="outline-success" @click.prevent="saveUnit()" size="sm"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="9" class="nopadding">
      <transition name="slide">
      <b-card>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t("common.search")}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <label v-if="data.item.name">{{JSON.parse(data.item.name)[$i18n.locale]}}</label>
            <label v-else></label>
          </template>
          <template slot="type" slot-scope="data">
            <label v-if="data.item.type==1000">system</label>
            <label v-else>{{$t("common.unit_type")}}</label>
          </template>
          <template slot="des" slot-scope="data">
            <label v-if="data.item.des">{{JSON.parse(data.item.des)[$i18n.locale]}}</label>
            <label v-else></label>
          </template>
           <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else>System</label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm" v-if="data.item.type<1000 || is_super">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t("common.delete")}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t("common.recover")}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" :title="$t('common.message')" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf,maxLength}
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'

export default {
  name: 'Unit',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      options_business: [],
      unit:{
        id:null,
        code:moment().valueOf().toString(),
        business:null,
        company_id:null,
        branch_id:null,
        user_create_id:null,
        user_update_id:null,
        name:null,
        logo:null,
        des:null,
        cocon:false,
        type:0
      }
    }
  },
  validations: {
    unit: {
      name:{
        required,
        maxLength:maxLength(50),
        async isUnique(value) {
          if (value==null) return true
          if(this.unit && this.unit.id!=null) return true
          const res = await this.nameUnique(value)
          return Boolean(res.data)
        }
      },
      business:{
        required: requiredIf(function () {
          return this.is_super
        })
      }
    }
  },
  computed: {
    ...mapGetters('st_unit', ['page_count','page','filter','per_page']),
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'branch',label:this.$t("common.company"),sortable: true})
        fields.push({key: 'business',label:this.$t("common.business"),sortable: true})
      }

      if(user.role==Role.Admin && user.company.cocon){
        fields.push({key: 'branch',label:'Cty/chi nhánh',sortable: true, variant:'info'})
      }
      fields.push({key: 'name',label:this.$t("common.name"),sortable: true, variant:'primary'})
      fields.push({key: 'code',label:this.$t("common.code"),sortable: true})
      fields.push({key: 'type',label:this.$t("common.type"),sortable: true})
      fields.push({key: 'des',label:this.$t("common.description"),sortable: true})
      fields.push({key: 'created_at',label:this.$t("common.date"),sortable: true})
      fields.push({key: 'status',label:this.$t("common.status"),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    is_super() {
      let user=this.$store.state.st_authentication.user
      debugger
      return user&&user.role==Role.Super_Admin
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    filter:{
      get: function(){
        return this.$store.state.st_unit.filter
      },
      set: function(text){
        this.$store.commit('st_unit/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_unit.ls_unit
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    async nameUnique(value){
      debugger
      let param={}
      if(this.is_super){
        param={business:this.unit.business,name:value,company_id:this.user_login.company_id,lang:this.$i18n.locale}
      }else{
        param={business:this.user_login.company.business,name:value,company_id:this.user_login.company_id,lang:this.$i18n.locale}
      }

      return await this.$store.dispatch(`st_unit/name_unique`,param)
    },
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    ...mapActions('st_unit', ['get_ls_unit','change_page', 'apply_filter']),
    clear(){
      this.unit={id:null,logo:null,des:null,business:null,company_id:null,branch_id:null,
      name:null,
      user_create_id:null,user_update_id:null,cocon:false,code:moment().valueOf().toString(),type:0}

      this.$refs.txt_name.$el.focus()
      this.$v.$reset()
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },

    async saveUnit() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing == true) {
        debugger
        return
      }

      // set the async state
      app.processing = true

      if(app.unit.id){
        app.$store.dispatch('st_unit/update',{lang:app.$i18n.locale,data:
        {id:app.unit.id,name:app.unit.name,business:app.unit.business,
        des:app.unit.des,user_update_id:app.user_login.id,logo:app.unit.logo}})
        .then(function (resp) {
          app.get_ls_unit()
          app.clear()
          app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        debugger
        app.unit.user_create_id=app.user_login.id
        if(app.is_super){
          app.unit.type=1000
          app.unit.status=1
        }else{
          app.unit.company_id=app.user_login.company_id
          app.unit.branch_id=app.user_login.branch_id
          app.unit.business=app.user_login.company.business
          app.unit.type=0
        }

        app.unit.name='{"en":"'+app.unit.name+'","vi":"'+app.unit.name+'"}'

        app.$store.dispatch('st_unit/create',app.unit)
        .then(function (resp) {
          app.get_ls_unit()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
      app.processing = false
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      debugger
      if(!item) return
      if(item.type==1000&&this.user_login.role!=Role.Super_Admin) return
      debugger
      let obj=this.unit = {...item}
      if(obj.name){
        let name=JSON.parse(obj.name)[this.$i18n.locale]
        this.unit.name=name
      }
      if(obj.des){
        this.unit.des=des
        let des=JSON.parse(obj.des)[this.$i18n.locale]
      }
    },
    searchLike:function(item){
      return item.name&&item.name.includes(this.filter)
      ||item.created_at.includes(this.filter)
      ||item.business.includes(this.filter)
      ||item.des&&item.des.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.unit.logo=e.target.result
      }
    },
    confirmDelete(obj) {
      debugger
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        // An error occurred
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_unit/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_unit()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    async deleteObj(obj) {
      let app=this
      debugger
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.unit")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_unit/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_unit()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    }
  },
  async mounted () {
    let vm=this
    vm.$refs.txt_name.$el.focus()
    vm.options_business=vm.option_business
    vm.get_ls_unit()
  }
}
</script>
